*, *::after, *::before {
  box-sizing: border-box;
  
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body{
  height:100vh
}

#root{
  background: rgb(250, 241, 194);
  height:100%
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url('https://fonts.googleapis.com/css2?family=Love+Ya+Like+A+Sister&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ribeye+Marrow&display=swap');

#profilePage{
  min-height: 100vh;
  min-width: 100vh;
  background-color: rgba(250, 241, 194, 1);
  display: flex
}

/*
DO NOT DELETE THIS, otherwise there is a large blank in the bottom of StoryDetailPage
*/
#quill svg{
  display: none;
}

.likedislikebutton{
  color: rgba(0, 0, 0, 0.54)!important;
  cursor: pointer;
}
.likedislikebuttonpress {
  color: rgba(0, 0, 0, 0.959)!important;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 15px #777777;
     -moz-box-shadow: inset 0px 0px 15px #777777;
          box-shadow: inset 0px 0px 15px #777777;
}