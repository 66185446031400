:root {
    --main-bg-color: #0cf;
}


.button {
    display: flex;
    padding: .75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    align-items: center;
    cursor: pointer;
    
   
  }

  .button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-bg-color);
    border-radius: 10rem;
    z-index: -2;
  }

  .button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(var(--main-bg-color), 15%);
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }

  .button:hover {
    color: black;
    
  }

  .button:hover:before {
    width: 100%;
  }